import React, { useEffect, useRef, useState } from 'react';
import {
  apiVideoFileChangeOrder,
  apiVideoFileCompleteLoading,
  apiVideoFileCreate,
  apiVideoFileDelete,
  apiVideoFileGenerateUploadLink,
  apiVideoFileRestore,
  apiVideoPreview,
} from '../../actions/videoActions';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Button from './Button';
import { CLOUDFRONT_URL } from '../../core/common';
import CyrillicToTranslit from 'cyrillic-to-translit-js';

const source = axios.CancelToken.source();

function VideosFiles({
  videoId,
  onChangeFiles,
  initialFiles = [],
  activeFileId,
  onChangeActiveFileId,
  clickable = false,
  moderation = false,
  onMovePress,
}: any) {
  const token = useSelector(state => state.user.token);
  const inputFile = useRef(null);

  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    setFiles(initialFiles);
  }, []);

  useEffect(() => {
    onChangeFiles(files);
  }, [files]);

  const onFileChange = (event: any) => {
    for (let i = 0; i < event.target.files.length; ++i) {
      uploadFile(event.target.files[i]);
    }
  };

  const uploadFile = async (file: any) => {
    // @ts-ignore
    const cyrillicToTranslit = new CyrillicToTranslit();
    let filename = cyrillicToTranslit.transform(file.name, '_');
    filename = filename.replace(/[^a-zA-Z0-9.]/g, '').replaceAll(' ', '_');
    let parts = filename.split('.');
    let extenstions = '';
    if (parts.length > 1) {
      extenstions = parts[parts.length - 1];
    }
    if (filename.trim().length === 4) {
      filename = `${Math.floor(Date.now() / 1000)}.${extenstions}`;
    }
    const fileSize = (file.size * 0.000001).toFixed(2);
    const generateLinkResponse = await apiVideoFileGenerateUploadLink(token, filename);

    // @ts-ignore
    const s3Path = generateLinkResponse.s3_path;
    // @ts-ignore
    const uploadLink = generateLinkResponse.upload_link;

    const fileResponse = await apiVideoFileCreate(token, s3Path, filename, fileSize, videoId);
    // @ts-ignore
    const fileId = fileResponse.file_id;
    // @ts-ignore
    const downloadLink = fileResponse.download_link;

    const config = {
      onUploadProgress: (progressEvent: any) => {
        // @ts-ignore
        changePercent(fileResponse.file_id, progressEvent);
      },
      cancelToken: source.token,
    };

    // @ts-ignore
    setFiles(files => [
      ...files,
      {
        id: fileId,
        s3Path,
        size: fileSize,
        filename,
        cancel: source,
        percent: 0,
        completed: false,
        num: files.length === 0 ? 0 : files[files.length - 1].num + 1,
      },
    ]);

    try {
      await axios.put(uploadLink, file, config);
      await completeUpload(fileId, downloadLink);
    } catch {}
  };

  const completeUpload = async (fileId: any, downloadLink: any) => {
  
    const response = await apiVideoFileCompleteLoading(token, fileId);
    console.log('ddd', response);
    if (response && response.metadata && response.metadata.fileSize) {
      console.log('ddd', response.metadata);
      setFiles((files: any) => {
        return files.map((file: any) => {
          if (file.id === fileId) {
            file.percent = 100;
            file.completed = true;
            file.downloadLink = downloadLink;
            file.width = response.metadata.width;
            file.height = response.metadata.height;
            file.duration = response.metadata.duration;
            file.fileSize = response.metadata.fileSize;
          }
          return file;
        });
      });
    }

  };

  const changePercent = (id: any, progressEvent: any) => {
    const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);

    setFiles((files: any) => {
      return files.map((file: any) => {
        if (file.id === id) {
          file.percent = percent;
        }
        return file;
      });
    });
  };

  const deleteFile = (id: any) => {
    const file = files.find((file: any) => file.id === id);
    if (file !== undefined) {
      if (file.completed && moderation) {
        setFiles([
          ...files.map((file: any) => {
            if (file.id === id) {
              file.deleted = 1;
            }
            return file;
          }),
        ]);
      } else {
        setFiles((files: any) => {
          return files.filter((file: any) => file.id !== id);
        });
      }

      apiVideoFileDelete(token, id, +moderation);
    }
  };

  const restoreFile = (id: any) => {
    const file = files.find((file: any) => file.id === id);
    if (file !== undefined) {
      if (file.completed) {
        setFiles([
          ...files.map((file: any) => {
            if (file.id === id) {
              file.deleted = 0;
            }
            return file;
          }),
        ]);
      } else {
        setFiles((files: any) => {
          return files.filter((file: any) => file.id !== id);
        });
      }

      apiVideoFileRestore(token, id);
    }
  };

  const up = (index: number) => {
    const _files = files;

    const current_num = _files[index].num;
    const prev_num = _files[index - 1].num;

    const current_file = _files[index];
    const prev_file = _files[index - 1];

    current_file.num = prev_num;
    prev_file.num = current_num;

    saveOrder(_files);
    setFiles([..._files]);
  };

  const down = (index: number) => {
    const _files = files;

    let current_num = _files[index].num;
    let next_num = _files[index + 1].num;

    let current_file = _files[index];
    let next_file = _files[index + 1];

    current_file.num = next_num;
    next_file.num = current_num;

    saveOrder(_files);
    setFiles([..._files]);
  };

  const saveOrder = (_files: any) => {
    apiVideoFileChangeOrder(
      token,
      JSON.stringify(
        _files.map((file: any) => {
          return JSON.stringify({
            id: file.id,
            num: file.num,
          });
        })
      )
    );
  };

  const preview = () => {
    setFiles([
      ...files.map((file: any) => {
        if (file.status === 1) {
          file.status = 2;
        }
        return file;
      }),
    ]);
    apiVideoPreview(token, videoId);
  };

  return (
    <>
      <div className="video-files">
        {files.length === 0 ? (
          <div className="video-files-empty">
            <span className="video-files-empty-text">
              To start the upload select files from your PC
            </span>
            <button
              className="video-files-empty-button"
              onClick={() => {
                // @ts-ignore
                inputFile.current.click();
              }}
            >
              Select files
            </button>
          </div>
        ) : (
          <>
            {files
              .sort((a: any, b: any) => (a.num > b.num ? 1 : b.num > a.num ? -1 : 0))
              .map((file: any, index: number) => {
                return (
                  <VideosFile
                    file={file}
                    onDelete={() => {
                      deleteFile(file.id);
                    }}
                    onRestore={() => {
                      restoreFile(file.id);
                    }}
                    isActive={file.id === activeFileId}
                    onChangeActiveFileId={onChangeActiveFileId}
                    clickable={clickable}
                    onUp={() => {
                      up(index);
                    }}
                    onDown={() => {
                      down(index);
                    }}
                    onMove={() => {
                      onMovePress(file.id);
                    }}
                    index={index}
                    isLast={index === files.length - 1}
                    moderation={moderation}
                  />
                );
              })}
            <span
              className="video-files-upload-button"
              onClick={() => {
                // @ts-ignore
                inputFile.current.click();
              }}
            >
              Upload file
            </span>
          </>
        )}
        <input
          accept={'video/*'}
          type="file"
          multiple
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onFileChange}
        />
      </div>
      {moderation && files.filter((file: any) => file.completed).length > 0 && (
        <Button
          className={'blue preview-button'}
          label={'Preview'}
          loading={false}
          onClick={preview}
          type={'button'}
        />
      )}
    </>
  );
}

function VideosFile({
  file,
  onDelete,
  onRestore,
  onMove,
  isActive,
  onChangeActiveFileId,
  clickable,
  onUp,
  onDown,
  index,
  isLast,
  moderation,
}: any) {
  const getUploadMB = (size: any, percent: any) => {
    return ((size * percent) / 100).toFixed(2);
  };

  const formatDuration = (seconds: number): string => {
    if (!seconds) return '0:00';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  if (!file.completed) {
    return (
      <div className="video-file-upload">
        <div className="video-file-upload-top">
          <span className="video-file-upload-title">{file.filename}</span>
          <span className="video-file-upload-close" onClick={onDelete} />
        </div>
        <div className="video-file-upload-line">
          <div className="video-file-upload-line-active" style={{ width: file.percent + '%' }} />
        </div>
        <div className="video-file-upload-bottom">
          <span className="video-file-upload-size">
            {getUploadMB(file.size, file.percent)} MB of{' '}
            {file.size && parseFloat(file.size).toFixed(2)} MB
          </span>
          <span className="video-file-upload-percent">Uploading... {file.percent}%</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`video-file ${isActive && 'active'} ${file.deleted == 1 && 'deleted'}`}
      onClick={() => {
        if (clickable) {
          onChangeActiveFileId(file.id);
        }
      }}
    >
      {file.status === 2 && (
        <div className="video-file-image">
          <div className="blue-spinner" />
        </div>
      )}
      {file.status === 3 && (
        <img className="video-file-image" src={`${CLOUDFRONT_URL}${file.thumbLink}`} />
      )}
      <div className="video-file-content">
        <span className="video-file-name">{file.filename}</span>
        <span className="video-file-size">Size: {file.fileSize && file.fileSize.toFixed(2)} MB</span>
        <span className="video-file-size">Duration: {formatDuration(file.duration)}</span>
        <span className="video-file-size">Resolution: {file.width}x{file.height}  </span>
      </div>
      <div className="video-file-icons">
        <a
          target={'_blank'}
          href={file.downloadLink}
          download
          className="video-file-icon fa fa-download"
        />
        {file.deleted === 0 ? (
          <span className="video-file-icon fa fa-trash" onClick={onDelete} />
        ) : (
          <span className="video-file-icon fa fa-refresh" onClick={onRestore} />
        )}
        {moderation && <span className="video-file-icon fa fa-reply-all" onClick={onMove} />}
      </div>
      {moderation && (
        <div className="video-file-arrows">
          {index !== 0 && <i className="fa fa-arrow-up" onClick={onUp} />}
          {!isLast && <i className="fa fa-arrow-down" onClick={onDown} />}
        </div>
      )}
    </div>
  );
}

export default VideosFiles;
